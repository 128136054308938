import axios from "axios"

import { GATSBY_INTEGROMAT_SEND_INQUIRY_URL } from "gatsby-env-variables"

export const sendInquiry = async ({ values, callback, errorCallback }) => {
  try {
    let response = await axios.post(GATSBY_INTEGROMAT_SEND_INQUIRY_URL, {
      ...values,
      program: values.program.value,
    })

    callback()
    return response
  } catch (error) {
    errorCallback()
  }
}
