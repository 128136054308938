import React, { useState, useEffect, Fragment } from "react"

import Section from "elements/Section"
import Message from "elements/Message"

import { Formik, Form } from "formik"

import { generateFormField } from "elements/Form/services/form"

import { useSectionFormFields } from "./hooks/useSectionFormFields"
import { generateInitialValues } from "services/context"

import Button from "elements/Button"

import { sendInquiry } from "./services/sendInquiry"

const ContactUs = ({ formFields, userData }) => {
  let exclude = []

  const [notification, setNotification] = useState({
    message: "",
    active: false,
  })
  const [loading, setLoading] = useState(false)

  let initialValues = {
    ...generateInitialValues({
      fields: formFields.filter((field) => !exclude.includes(field.name)),
    }),
  }

  let { sectionFormFields, validationSchema } = useSectionFormFields({
    formFields: formFields.filter((field) => !exclude.includes(field.name)),
  })

  useEffect(() => {
    if (notification.active)
      setTimeout(() => {
        closeNotification()
      }, 5000)
  }, [notification])

  const closeNotification = () => {
    setNotification({ message: "", active: false, type: "" })
  }

  const handleSubmit = (values, { resetForm }) => {
    setLoading(true)
    sendInquiry({
      values,
      callback: () => {
        resetForm()
        setLoading(false)
        setNotification({
          active: true,
          type: "success",
          message:
            "Thank you for reaching out to MedGrocer’s teleconsult service. Our team will review your message and get back to you within 24 to 48 hours.",
        })
      },
      errorCallback: () => {
        setLoading(false)
        setNotification({
          active: true,
          type: "danger",
          message:
            "Something went wrong with sending your email. Please try again.",
        })
      },
    })
  }

  return (
    <Formik
      initialValues={{ ...initialValues, ...userData }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue, errors }) => (
        <Form>
          {sectionFormFields.map((section) => (
            <Section
              title={section?.section}
              subtitle={section?.subtitle || ""}
            >
              {section?.message && (
                <Message color="light">{section?.message}</Message>
              )}
              {section?.fields.map((field, index) => {
                if (!field?.referenceAnswer) {
                  return (
                    <Fragment>
                      {generateFormField({
                        formFields: section?.fields,
                        formField: field,
                        values,
                        setFieldValue,
                      })}
                      {!!field?.addDividerAfterField && (
                        <hr className="has-background-light" />
                      )}
                    </Fragment>
                  )
                }
                return null
              })}
            </Section>
          ))}
          {notification.active && (
            <div className={`notification is-${notification.type}`}>
              {/* eslint-disable-next-line */}
              <button
                className="delete"
                type="button"
                onClick={closeNotification}
              />
              {notification.message}
            </div>
          )}
          <Button color="primary" type="submit" isLoading={loading} isFullwidth>
            Submit
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default ContactUs
