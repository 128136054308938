export const reduceHelpCenterQuestions = (dataMapElement) => {
    // Change data format from Airtable to fit fuzzySearch
    let pageSections = dataMapElement.reduce((pageSections, question) => {
      let sectionName = question.section
  
      // Maps form field data to corresponding section
      let currentSection = pageSections.find(
        (pageSection) => pageSection.section === sectionName
      )
      let currentSectionFields = currentSection ? currentSection.fields : []
  
      currentSectionFields = [...currentSectionFields, question]
      currentSectionFields = currentSectionFields.sort(
        (firstFormField, secondFormField) =>
          firstFormField.order - secondFormField.order
      )
  
      let newPageSection = {
        section: question.section,
        fields: currentSectionFields,
        order: question.sectionOrder,
      }
  
      let tempPageSections = pageSections.filter(
        (pageSection) => pageSection.section !== sectionName
      )
      tempPageSections = [...tempPageSections, newPageSection]
  
      return tempPageSections
    }, [])
  
    return pageSections
  }