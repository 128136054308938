import { useStaticQuery } from "gatsby"

const ALL_HELP_CENTER_QUESTIONS = graphql`
query AirtableStaticFormFields {
    allAirtableallHelpCenterContent(
        sort: {fields: data___Order}
    ) {
      nodes {
        data {
          Answer {
            childMarkdownRemark {
              html
            }
          }
          Main_Section
          Main_Section_Id
          Order
          Question
          Section
        }
      }
    }
  }
`

export const useHelpCenterQuestions = () => {
    const data = useStaticQuery(ALL_HELP_CENTER_QUESTIONS)
    return data
}