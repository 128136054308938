import React, { Fragment, useState, useEffect } from "react"

import Layout from "layout"
import Container from "layout/Container"
import Collapsible from "elements/Collapsible"
import ContactUs from "./HelpCenter/ContactUs"
import LimitationOfLiability from "./TermsAndConditions/LimitationOfLiability"

import HelpCenterSearch from "./HelpCenter/HelpCenterSearch"
import { useHelpCenterQuestions } from "./hooks/useHelpCenterQuestions"
import { reduceHelpCenterQuestions } from "./services/reduceHelpCenterData"

import styles from "./utils/staticPages.module.scss"
import classNames from "classnames"

const StaticPage = (props) => {
  const { pageContent, module } = props.pageContext
  const hasContactUs = module.title === "Help Center"
  const [searchQuery, setSearchQuery] = useState("")
  const [filteredFields, setFilteredFields] = useState([])
  const hasLimitationOfLiability =
    module.title === "Terms and Conditions"

  const physicalHealthFaqs = pageContent.filter(
    (section) => section.mainSection === "Physical Health FAQs"
  )
  const mentalHealthFaqs = pageContent.filter(
    (section) => section.mainSection !== "Physical Health FAQs"
  )

  const handleSearchChange = async (event) => {
    setSearchQuery(event.target.value)
  }

  let data = useHelpCenterQuestions()
  let airtablehelpCenterQuestions = data.allAirtableallHelpCenterContent.nodes.map(
    (node) => node.data
  )

  let sectionHelpCenterQuestions = reduceHelpCenterQuestions(
    airtablehelpCenterQuestions.map((formField) => ({
      ...formField,
      section: formField.section,
    }))
  )[0].fields

  useEffect(() => {
    if (!searchQuery) {
      setFilteredFields([])
      return
    }
    setFilteredFields(
      sectionHelpCenterQuestions.filter((section) =>
        section.Question.toLowerCase().includes(searchQuery?.toLowerCase())
      )
    )
  }, [searchQuery])

  const renderFaqs = (faqs) => {
    return (
      <div className="pl-2 pl-0-mobile">
        {faqs.map((content) => {
          const currentSection = faqs.find(
            (sectionContent) => sectionContent.section === content?.section
          )
          const isFirstInSection =
            content?.answer?.childMarkdownRemark?.html ===
            currentSection?.answer?.childMarkdownRemark?.html
          if (content?.question && content?.answer) {
            return (
              <Fragment key={content.section}>
                {isFirstInSection && (
                  <h3 className="mb-1 mt-2">{content.section}</h3>
                )}
                <Collapsible title={content.question}>
                  {/* <p
                    dangerouslySetInnerHTML={{
                      __html: content.answer.childMarkdownRemark.html.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  ></p> */}
                  <p
                    dangerouslySetInnerHTML={{
                      __html: content.answer.childMarkdownRemark.html
                        .replace(
                          /<h3/g,
                          '<p class="header-size-text has-text-weight-bold"'
                        )
                        .replace(/<strong/g, '<strong class="label-size-input"')
                        .replace(/<a/g, '<a class="label-size-input"')
                        .replace(/\n/g, "<br />"),
                    }}
                  ></p>
                </Collapsible>
              </Fragment>
            )
          }
          return null
        })}
      </div>
    )
  }

  return (
    <Layout
      seoTitle={module.seoTitle}
      display={{ helpCenterBanner: false, footer: true }}
      pageContext={props.pageContext}
      polygonStyle="detailsForms"
    >
      <Container isCentered customClassName={classNames("mt-2-mobile mt-5 mb-4")}>
        <h1 className="has-text-primary has-text-centered mb-2">
          {module.title === "Frequently Asked Questions"
            ? "How can we help you?"
            : module.title}
        </h1>
        {module.seoTitle === "Frequently Asked Questions" && (
          <HelpCenterSearch handleOnChange={handleSearchChange} />
        )}
        {filteredFields.map((section) => (
          <div>
            <h2>{section.section}</h2>
            <Collapsible title={section.Question}>
              <p
                dangerouslySetInnerHTML={{
                  __html: section.Answer.childMarkdownRemark.html
                    .replace(
                      /<h3/g,
                      '<p class="header-size-text has-text-weight-bold"'
                    )
                    .replace(/<strong/g, '<strong class="label-size-input"')
                    .replace(/<a/g, '<a class="label-size-input"')
                    .replace(/\n/g, "<br />"),
                }}
              ></p>
            </Collapsible>
          </div>
        ))}
        {pageContent?.[0]?.body && (
          <div
            className="mb-2 content"
            dangerouslySetInnerHTML={{
              __html: pageContent?.[0]?.body.childMarkdownRemark.html
                .replace(
                  /<h3/g,
                  '<p class="header-size-text has-text-weight-bold"'
                )
                .replace(/<strong/g, '<strong class="label-size-input"')
                .replace(/<a/g, '<a class="label-size-input"')
                .replace(/\n/g, "<br />"),
            }}
          />
        )}
        {module.title === "Frequently Asked Questions" ? (
          <>
            <h2 className="mt-2 has-text-primary">Mental Health</h2>
            {renderFaqs(mentalHealthFaqs)}
            <h2 className="mt-2 has-text-primary">Physical Health</h2>
            {renderFaqs(physicalHealthFaqs)}
          </>
        ) : (
          <>
            {pageContent.map((content) => {
              const currentSection = pageContent.find(
                (sectionContent) => sectionContent.section === content?.section
              )
              const isFirstInSection =
                content?.answer?.childMarkdownRemark?.html ===
                currentSection?.answer?.childMarkdownRemark?.html
              if (content?.question && content?.answer) {
                return (
                  <Fragment key={content.section}>
                    {isFirstInSection && <h2>{content.section}</h2>}
                    <Collapsible title={content.question}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: content.answer.childMarkdownRemark.html
                            .replace(
                              /<h3/g,
                              '<p class="header-size-text has-text-weight-bold"'
                            )
                            .replace(
                              /<strong/g,
                              '<strong class="label-size-input"'
                            )
                            .replace(/<a/g, '<a class="label-size-input"')
                            .replace(/\n/g, "<br />"),
                        }}
                      ></p>
                    </Collapsible>
                  </Fragment>
                )
              }
              return null
            })}
          </>
        )}
        {hasContactUs && <ContactUs module={module} />}
        {hasLimitationOfLiability && <LimitationOfLiability />}
      </Container>
    </Layout>
  )
}

export default StaticPage
